#tipps {
    input#balance {
        text-align: center;
    }
    .btn-group.theme {
        // Fix for .btn-group > a.btn having big feet
        align-items: baseline;
    }
    .idea {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        @include media-breakpoint-down(lg) {
            font-size: 88%;
        }
        @include media-breakpoint-down(md) {
            font-size: 72%;
        }

        &.x-trades {
            .col.status {
                min-width: 13%;
                .badge {
                    white-space: break-spaces;
                }
            }
            .md-truncate {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            @include media-breakpoint-down(md) {
                .ticker, .initial_stop {
                    order: 1;
                }
                .entry_price {
                    order: 2;
                }
                .shares, .modified_stop {
                    order: 3;
                }
                .take_profit {
                    order: 4;
                }
                .risk, .status {
                    order: 5;
                }
            }
        }

        &.pro-strategy {
            @include media-breakpoint-down(sm) {
                .market {
                    order: 1;
                }
                .ticker {
                    order: 3;
                }
                .risk {
                    order: 7;
                }
                .entry_price {
                    order: 2;
                    text-align: right;
                }
                .initial_stop {
                    order: 4;
                    text-align: right;
                }
                .take_profit {
                    order: 6;
                    text-align: right;
                }
                .direction {
                    order: 5
                }
                .status {
                    order: 12;
                }
            }
        }

        &.fancy {
            .text-muted {
                margin-top: 0.7em;
                font-size: 90%;
            }
            .together {
                > * {
                    display: inline;
                }
            }
        }

        .glow-transparent {
            margin-left: -5px;
            border: 5px solid transparent;
            border-radius: 50%;
        }
        .glow-pink {
            margin-left: -5px;
            border: 5px solid pink;
            border-radius: 50%;
        }

        .badge-status {
            &.badge-order-open {
                color: black;
                background-color: #b7e1cd;
            }
            &.badge-order-cancelled {
                color: white;
                background-color: #b7b7b7;
            }
            &.badge-trade-open {
                color: white;
                background-color: #57bb8a;
            }
            &.badge-take-profit {
                color: black;
                background-color: #00ff00;
            }
            &.badge-stop-loss {
                color: white;
                background-color: #ff0000;
            }
            &.badge-modified-stop-loss {
                color: black;
                background-color: #ffa500;
            }
            &.badge-individual-close {
                color: white;
                background-color: #c8a52e;
            }
        }

    }
}
