.priority-indicator {
    --col: #ddd;
    display: flex;
    flex-direction: row;
    .level {
        width: 1.5em;
        height: 0.8em;
        // border: 1px solid pink;
        background-color: var(--col);
        border-radius: 0.2em;
        margin-left: 0.5px;
        margin-right: 0.5px;
    }
}
