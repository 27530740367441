@use 'sass:math';
@import "./colors";
@import "./_variables";

.content.container {
    padding-top: calc(var(--top-bar-height) + #{$top-padding});
    padding-bottom: 100px;
}

body {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    color: $text-color;
}

a {
    color: $link-color;
}

.form-control::placeholder {
    color: #aaa !important;
}

.btn-primary,
.btn-outline-primary:hover {
    background-color: $link-color;
}

.btn-outline-primary {
    border-color: $link-color;
    color: $link-color;
}
