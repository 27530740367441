form {
    .color-radio {
        display: flex;
        gap: 0.3rem;
        label.color {
            --col: #ddd;
            --col-light:  oklch(
                from var(--col)
                calc(l * 1.5)
                calc(c * 0.6)
                h
            );
            width: auto;
            display: flex;
            flex-direction: column;
            border-radius: 3px;
            border: 0.2rem solid var(--col);
            .input {
                background-color: var(--col);
                input[type="radio"] {
                    visibility: hidden;
                }
                &:has(input[type="radio"][value="0"]) {
                    background-image: linear-gradient(to right, var(--col), black, var(--col));
                }
            }
            .name {
                padding: 0.3rem;
                user-select: none;
            }
            &:has(.input input[type="radio"]:checked) {
                border-color: currentcolor;
                .name {
                    background-color: var(--col-light);
                }
            }
        }
    }
}

span.named-color {
    --col: transparent;
    padding: 0.3rem;
    border: 3px solid var(--col);
    border-radius: 3px;
}
