@use "sass:math";
@import "colors";
@import "helpers";
$newsletter-e-size: 2.4rem;
$newsletter-e-fac: 1.4rem;
$newsletter-e-border: 0.5rem;
$newsletter-e-border-bottom: 0.7 * $newsletter-e-border;
$newsletter-e-spc: math.div($newsletter-e-size - $newsletter-e-fac, 2.0);
$newsletter-e-fac2: $newsletter-e-fac + $newsletter-e-spc;
#gratis_newsletters {
    &.multi {
        .l {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            text-overflow: ellipsis;
            .e, .d {
                width: $newsletter-e-size;
                text-align: center;
                line-height: $newsletter-e-size;
                overflow: hidden;
                flex-shrink: 0;
            }
            .e {
                position: relative;
                @include center-child-vertically;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: $newsletter-e-spc;
                    width: $newsletter-e-fac;
                    height: 100%;
                    background: $link-color;
                    z-index: -1;
                }
                input[type=checkbox] {
                    margin: 0 auto;
                }
            }
            .d {
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: $newsletter-e-fac2;
                    height: $newsletter-e-fac2;
                    border-top-left-radius: $newsletter-e-border;
                    background: $link-color;
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: $newsletter-e-spc;
                    height: $newsletter-e-spc;
                    border-top-left-radius: $newsletter-e-border;
                    background: white;
                }
            }
            .email {
                padding-left: $newsletter-e-spc + $newsletter-e-fac;
                position: relative;
                height: $newsletter-e-size;
                line-height: $newsletter-e-size;
                text-overflow: ellipsis;
                flex-basis: auto;
                overflow: hidden;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: $newsletter-e-spc;
                    height: $newsletter-e-fac;
                    width: $newsletter-e-fac;
                    background: $link-color;
                }
            }
            &:last-of-type {
                .e::before {
                    border-bottom-left-radius: $newsletter-e-border-bottom;
                    border-bottom-right-radius: $newsletter-e-border-bottom;
                }
            }
        }
    }
    input[type="submit"] {
        margin-top: 1em;
    }
}
