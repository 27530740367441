@use 'sass:math';
:root {
    --transspd: 0.6s;
}

@mixin make-square($height: 100%) {
    &::before {
        content: "";
        padding-top: $height;
        display: block;
    }
}

@mixin pseudo-cover($kind, $force-pos: 0) {
    @if $force-pos != 0 {
        position: relative;
    }
    &::#{$kind} {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@mixin grid-fill-parent() {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


@mixin grid-center-element() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin backface-visibility-hidden() {
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

@mixin flip-card() {
    --shadow-y-offset: 4px;
    perspective: 1000px;
    background-color: transparent;
    > .flip-card-inner {
        @include grid-fill-parent();
        text-align: center;
        transition: all 0.6s ease-in-out;
        transform-style: preserve-3d;
        box-shadow: var(--shadow-y-offset) 4px 8px 4px rgba(0, 0, 0, 0.3);
        > .front, > .back {
            @include backface-visibility-hidden;
        }
        > .back {
            transform: rotateY(180deg);
        }
    }
    &:focus {
        outline: 0;
    }
    &:focus, &:hover, &.flipped {
        --shadow-y-offset: -4px;
        > .flip-card-inner {
            transform: rotateY(180deg);
        }
    }
}

@mixin naked-list() {
    padding: 0;
    list-style: none;
    margin: 0;
}

@mixin grid-element-label() {
    background-color: #333a;
    color: #fff;
    border-radius: 0.3em;
    padding: 0.5em;
    text-align: center;
}

@mixin greyscale($max: 1) {
    filter: grayscale($max);
    transition: filter 0.6s ease-in-out;
    &:hover {
        filter: grayscale(math.div($max, 2));
    }
    &:has(.shop-link:hover) {
        filter: none;
    }
}
