@import "my/grid";

.my-tile-grid.reports {
    --gap: 6.6rem;
    $perc-height: 130%;
    --item-size: 200px;
    .report {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 0.3rem;
        .cover {
            aspect-ratio: var(--cover-aspect-ratio, 1.0);
            background-image: var(--cover-image, none);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .name {
            margin-top: 1.7rem;
            font-weight: 700;
        }

        .disclaimer {
            margin-top: 1em;
            font-size: 70%;
            text-align: center;
            transition: color var(--transspd) ease-in-out;
            @media (min-width: $mobile-bp + 1) {
                color: $text-color-highlight;
                a.dsvgo {
                    color: $text-color-highlight;
                    transition: color var(--transspd) ease-in-out;
                }
            }
            @media (max-width: $mobile-bp) {
                > .more {
                    display: none;
                }
            }

        }
        &:has(a.download:hover) {
            .disclaimer {
                color: $text-color-mute;
                a.dsvgo {
                    color: $link-color;
                }
            }
        }

        a.download, a.shop {
            // Align to the end of the flex box, but keep minimal 1rem distance
            --top-dist: 1rem;
            margin-top: auto;
            margin-bottom: var(--top-dist);
            transform: translateY(var(--top-dist));
            align-self: center;
        }
        &.not-owned {
            //@include greyscale(0.5);
        }

        &.fancy {
            perspective: 1000px;
            perspective-origin: left top;
            .cover {
                transform-style: preserve-3d;
                transform:
                    rotateX(10deg)
                    rotateY(25deg)
                    scale(0.75)
                ;
                transition:
                    transform var(--transspd) ease-in-out,
                    box-shadow var(--transspd) ease-in-out
                ;
                box-shadow: 7px 2px 15px 5px rgba(0, 0, 0, 0.15);
                &::after {
                    content: "";
                    background-color: #eee;
                    @include grid-fill-parent;
                    transform-origin: left center;
                    transform-style: preserve-3d;
                    transform:
                        rotateY(90deg)
                        scaleX(0.11)
                    ;
                }
            }
            &:hover .cover {
                box-shadow: 2px 2px 25px 10px rgba(0, 0, 0, 0.11);
                transform:
                    translateZ(2em)
                    rotateX(5deg)
                    rotateY(10deg)
                    scale(0.88)
                ;
            }
        }
    }
}
