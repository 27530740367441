h3.striped {
    font-size: 100%;
    font-weight: 700;
    padding: var(--padding-list);
    border-top: 1px solid var(--color-stripe-border);
    border-bottom: 1px solid var(--color-stripe-border);
    margin: 0;
    line-height: 1.5;
}

ul.striped {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding: var(--padding-list);
        border-top: 1px solid var(--color-stripe-border);

        &:nth-of-type(2n+1) {
            background-color: var(--color-stripe);
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;

        .grow {
            flex-basis: 100%;
        }
    }
}
