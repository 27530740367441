.custom-block {
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.custom-block .list-group-flush>li:nth-child(1) {
    border-top: none !important;
}

.custom-block .list-group-flush>li {
    padding: 20px 0;
}
