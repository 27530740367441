@import "./_variables";

//
// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
//
//= require trix/dist/trix

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
// TODO: probably not needed anymore
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

// Make editor window big by default
form .ck-content.ck-editor__editable {
  min-height: 500px;
}

trix-toolbar {
    position: sticky !important;
    z-index: 1;
    top: 1.1 * $top-bar-height;
    background-color: #eeeeee !important;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.35);
}

// Center images by default
.ck-content {
    // _blob.html
    figure.attachment {
        text-align: center;
    }
}
