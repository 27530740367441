figure.image_resized.image-zoomable {
    cursor: zoom-in;

    &.image-zoomed {
        cursor: zoom-out;
        position: fixed;
        z-index: 2;
        inset: var(--top-bar-height) 0 0 0;
        margin: 0;
        max-width: initial;
        width: auto !important;
        background-color: #000000EE;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;

        img {
            object-fit: contain;
            object-position: 50% 50%;
            width: 100% !important;
            height: 100%;
            min-width: initial;
        }
    }
}
