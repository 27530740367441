.speech-bubble-top-center::before {
  --sbSize: 0.5rem;
  --width: calc(var(--sbSize) / 2 * 3);
  height: var(--sbSize);
  width: var(--width);
  content: '';
  position: absolute;
  bottom: 100%;
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 100%, 50% 0, 87.5% 100%);
  margin-top: var(--sbSize);
  background: inherit;
}
