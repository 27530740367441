@use 'sass:math';
@import "colors";
@import "corporate-identity";
@import "custom-block";
@import "forms";

$icon-spacing: 0.4em;

:root {
    --top-bar-height: #{$top-bar-height};
    @media (max-width: $mobile-bp) {
        --top-bar-height: #{math.div($top-bar-height, 2)};
    }
}

.calendar-nav-button>span {
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
}

.calendar-nav-button {
    border-color: #ddd;
    color: #888;
}

.calendar-nav-button:hover {
    background-color: #ddd;
}

#header {
    #user .dropdown-toggle {
        color: $link-color;
    }

    img.product-favicon {
      display: inline;

      &.svg-icon {
          width: 1rem;
          transform: translateY(-12%);
      }
    }

    .container-fluid {
        background-color: var(--color-product-navigation, $nbc-blue);
        color: var(--color-product-navigation-text, $nbc-white);
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    }

    .nav-link {
        color: var(--color-product-navigation-text, $nbc-white);
        &:hover {
            color: var(--color-product-navigation-text-hover, #fff);
        }
        &.active {
            font-weight: bold;
        }
    }

    .row {
        height: var(--top-bar-height);
    }

    .dropdown-toggle {
        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            &:hover {
                background-color: var(--color-product-dropdown-hover-bg, #ddd);
                color: var(--color-product-dropdown-hover-fg, #888);
            }
            &:focus, &:active {
                background-color: var(--color-product-dropdown-active-bg, #ddd);
                color: var(--color-product-dropdown-active-fg, #888);
            }
            img.product-favicon {
                margin-right: $icon-spacing;
            }
            span[class^='icon-']:before {
                margin-right: $icon-spacing;
                color: $link-color;
            }
        }
    }

    .navbar-toggler {
        &[aria-expanded="true"] {
            border-color: var(--color-product-dropdown-active-bg, #fff);
        }
    }


    .mobile-menu {
        background-color: rgba( var(--color-product-navigation-rgb), 0.9);

        .nav-item {
            border-top: 1px var(--color-product-navigation-text, #fff) solid;
            &:has(+ .dropdown-divider) {
                border-bottom: 1px var(--color-product-navigation-text, #fff) solid;
            }
        }
        .nav-item>a {
            color: var(--color-product-navigation-text, #fff);

            img.product-favicon {
                margin-right: $icon-spacing;
            }
            span[class^='icon-']:before {
                margin-right: $icon-spacing;
            }
        }
        .dropdown-divider {
            margin: 0.1rem 0;
        }
    }

    #user .dropdown-toggle,
    .menu-toggle button {
        font-size: 32px;
    }

}

#header .menu-toggle button,
#header .dropdown-toggle
 {
    color: var(--color-product-navigation-text, $nbc-white);
}

/*
table a {
    color: #999;
}
*/

table a:hover {
    text-decoration: none;
    /*color: #999;*/
}

.flat-links a {
    color: #999;
}

.flat-links a:hover {
    text-decoration: none;
    color: #999;
}

#expenses .stock {
    display: inline-block;
    cursor: pointer;
    margin-inline: 0.2rem;
}

#expenses .stock:after {
    content: ",";
    margin-left: -3px;
    padding-right: 4px;
    display: inline-block;
}

#expenses .stock:last-child:after {
    content: "";
}

span.status {
    width: fit-content;
    padding: 0 3px;
    border-radius: 2px;
    color: #fff;
    display: block;
    a.stock & {
        display: initial;
    }
}

span.status.new {
    background-color: $color-status-new;
}

span.status.update {
    background-color: $color-status-update;
}

span.status.suspended {
    background-color: $color-status-suspended;
}

.breadcrumbs {
    margin: 2rem 0;
    color: $text-color;
    font-size: .8rem;
}

.breadcrumbs ul {
    padding-left: 0;
    list-style: none;
}

.breadcrumbs ul li {
    display: inline-block;
    margin: 0;
}

.breadcrumbs ul li a {
    color: inherit;
    text-decoration: none;
}

.breadcrumbs ul li a:hover {
    text-decoration: underline;
}

.breadcrumbs ul li:after {
    content: "≫";
    display: inline-block;
    margin-left: .5rem;
}

.breadcrumbs ul li:last-of-type:after {
    content: "";
}

#page-title {
    margin-top: 0.5rem;
}

.dropdown-menu-arrow {
    top: -25px;
    left: 50%;
    width: 0;
    height: 0;
    position: relative;
}

.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 7px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
}

.dropdown-menu-arrow:after {
    bottom: -18px;
    right: -8px;
    border-bottom-color: #fff;
}

.dropdown-menu-arrow:before {
    bottom: -17px;
    right: -8px;
    border-bottom-color: rgba(0, 0, 0, .15);
}

.text-editor-content {
    overflow: auto;
}

.sl-col-1 {
    width: 20%
}

.sl-col-2 {
    width: 20%
}

.sl-col-3 {
    width: 30%
}

.sl-col-4 {
    width: 15%
}

.sl-col-5 {
    width: 10%
}

.sl-col-actions {
    width: 5%;
}

.sl-col-345 {
    width: 55%
}
