@import "my/profile";
@import "my/products";
@import "my/reports";
@import "my/gratis_newsletters";
@import "my/grid";

.my-tile-grid {
    display: grid;
    --gap: #{$grid-spacing};
    gap: var(--gap);
    --item-size: 130px;
    grid-template-columns: repeat(auto-fill, minmax(var(--item-size), 1fr));

    @media (min-width: $tablet-bp) {
        --item-size: 270px;
    }
}
