@use 'sass:math';
$calendar-cell-line-height: 1.2em;
$calendar-cell-title-lines: 1;
$today-color: #007bff57;

.event-calendar {

    // Only take as much space as needed, but keep days without any events a
    // certain size
    .today {
        background-color: $today-color;
    }

    table {
        table-layout: fixed;
    }

    th {
        width: math.div(100%, 7);
    }

    .calendar-heading {
        .calendar-title {
            // Title on its own line on mobile
            @media (max-width: 991px) {
                width: 100%
            }
        }
    }

    .prev-month {
        background: rgba(0, 0, 0, 0.05);

        .day-label {
            opacity: 0.3;
        }
    }

    .next-month {
        background: rgba(0, 0, 0, 0.05);

        .day-label {
            opacity: 0.3;
        }
    }



    .day {
        min-height: 80px;
        height: auto;

        .day-label {
            color: #888;
            font-size: 100%;
        }

        position: relative;
        a.new-event {
            font-size: 200%;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            display: none;
            line-height: 0.5em;
        }
        &:hover a.new-event {
            display: block;
        }
    }

    .event {
        // variables that are customized from rails views
        --col: #ddd;
        --col-light:  oklch(
            from var(--col)
            calc(l * 1.5)
            calc(c * 0.6)
            h
        );
        // TODO: make the calendar actually beautiful
        // border: 1px solid white;
        border-radius: 3px;
        margin-bottom: 0.5rem;
        padding: 5px;
        overflow: hidden;

        background-color: var(--col);

        .title {
            margin-top: 5px;
            font-size: 90%;
            display: block;
            height: $calendar-cell-title-lines * $calendar-cell-line-height;
            line-height: $calendar-cell-line-height;
            position: relative;
            overflow: hidden;
            color: black;

            &:hover {
                text-decoration: underline;
            }

            &:after {
                content: "";
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 30%;
                height: $calendar-cell-line-height;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--col), 50%);
            }

            &:hover {
                &:after {
                    display: none;
                }
            }
        }

        .stock {
            a {
                color: black;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                margin-left: 1px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .time {
            display: block;
            margin-left: -5px;
            margin-right: -5px;
            padding-left: 5px;
            background-color: var(--col-light);

            a {
                color: black;
                display: inline;
                flex-direction: row;
                flex-wrap: nowrap;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .severity-indicator-stars {
            letter-spacing: -0.1rem;
            display: flex;
            flex-direction: column;
            float: right;
            line-height: 0.7;
            font-size: 69%;
            margin-right: 0.2rem;
            opacity: 0.7;
        }
    }

}

.aktie-column {
    display: block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table.events {
    .event-list-headline th {
        border-top: hidden;
    }

    td.line {
        background-color: $today-color;
        padding: 0;
        height: 0.5em;
    }
   .star {
       color: var(--col);
       font-size: 2rem;
       text-shadow: 1px 1px 2px black
   }
}

form.new_scheduled_event,
form.edit_scheduled_event {
    trix-editor {
        height: 10em;
    }
}
