@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.mood-gauge {
  width: clamp(10rem, 95%, 40rem);
  --rot: 0deg;
  // must rotate the arrow back to 0
  --base-rot: 151deg;

  @include media-breakpoint-down(md) {
    #header & {
      width: 3rem;;
    }
  }
  svg.gauge-img {
    width: 100%;
    height: auto;
    //aspect-ratio: 1;
  }
  .gauge-arrow {
    animation: 5s spin;
    transform: rotateZ(calc(var(--rot) - var(--base-rot)));
    transform-origin: 51.1% 135.8%;
  }
  @keyframes spin {
    0% { transform: rotateZ(calc(0deg - var(--base-rot))); }
  }
}

.mood-description {
  #header & {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding-block: 1rem;
  }
}
