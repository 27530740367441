// Sharedeals-specific bootstrap classes

.btn-sd {
    --bg: #555;
    --fg: oklch(
        from var(--bg)
        calc(l * 1.5)
        calc(c * 0.25)
        h
    );

    background-color: var(--bg);
    border-color: var(--bg);
    color: var(--fg);

    &:hover, &:focus-visible {
        background-color: oklch(
            from var(--bg)
            calc(l * 1.1)
            calc(c * 1.1)
            h
        );
        color: oklch(
            from var(--fg)
            l
            calc(c * 2.25)
            h
        );
    }
}

.btn-sd-primary {
    --bg: var(--color-sd-primary);
}

.btn-sd-secondary {
    --bg: var(--color-sd-secondary);
}
