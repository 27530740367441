@import "variables";
@import "css-functions";

.ck-content {
    a:not(.video-link,.room-link,.content-page-link,.gratis-report-link) {
        text-decoration: underline;
        color: var(--color-sd-primary);
    }
    .embed {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-block: 1rem;
        .video {
            width: css-min(100%, 850px);
            aspect-ratio: 16/9;
            display: grid;
            align-items: stretch;
            justify-items: stretch;
        }
        .caption {
            margin-top: 0.7em;
            color: #777;
        }
    }
    table {
      thead tr th, tbody tr td {
        vertical-align: middle;
      }
    }
    .iframe {
        position: relative;
        iframe.gspreadsheet {
            width: 100%;
            height: 80vh;
        }
        &:has(input[type=checkbox].toggle-fullscreen:checked) {
            position: fixed;
            inset: var(--top-bar-height) 0 0 0;
            z-index: 2;
            iframe {
                height: 100%;
            }
            .toggle-fullscreen {
                + label {
                    cursor: zoom-out;
                }
            }
        }
        .toggle-fullscreen {
            visibility: hidden;
            position: absolute;
            + label {
                position: absolute;
                right: 0.3rem;
                top: 0.3rem;
                cursor: zoom-in;

            }
        }
    }
    figure.media > iframe {
        display: block;
        width: css-min(100%, 850px);
        aspect-ratio: 16/9;
        height: auto;
        margin: 0 auto;
    }
    // When clicking links in TOC, target heading should not disappear under header.
    h2,h3,h4,h5,h6 {
        scroll-margin-top: $scroll-offset;
    }

    // center legacy images from trix-editor + old ckeditor "full" view
    .trix-content > p > img {
        display: table;
        clear: both;
        text-align: center;
        margin: 1em auto;
    }

    .attachment img {
        max-width: 100%;
        height: auto;
    }

    img {
        display: inline;
        margin-inline: 0.7em;
    }

    // ContentPage#extra_css_classes

    body.glossar-table & table {
        border: 5px ridge var(--color-product-navigation-text, #777777);
        td {
            border: 1px solid var(--color-product-navigation, #111111);
            padding: 10px;
            &:first-child {
                text-align: center;
                font-size: 1.25rem;
                font-weight: bolder;
                line-height: 1.2;
            }
            &:last-child {
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 1.2;
            }
        }
    }
    .zoom-embed {
        display: flex;
        justify-content: center
    }

    // Big headlines
    @media (max-width: $mobile-bp) {
        word-break: break-word;
        .table-responsive {
            word-break: initial;
        }
    }
}

#toc {
    box-shadow: -3px 3px 10px 0 rgba(0, 0, 0, 0.35);
    ul {
        list-style: none;
        padding-left: 1em;
        margin-bottom: 0.3em;
    }
}
