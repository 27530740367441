$nbc-blue:  #00184D;
$nbc-white: #fff;

$link-color: #4285BB;
$text-color: #4c4c4c;
$text-color-highlight: #eeeeee ;
$text-color-mute: #8d8d8d;
$color-warn: #C64638;

$color-status-new: #A8C638;
$color-status-update: #EAB44B;
$color-status-suspended: $color-warn;

$color-registration-todo: #EAB44B;
$color-registration-done: #a8c638;
$color-registration-active: lighten($color-registration-done, 15%);

$color-sd-primary: #4285bb;
$color-sd-secondary: #ADC64C;
