.videos {
    &.my-tile-grid {
        --item-size: 350px;
        --play-size: 5rem;
        margin-block: 3rem;

        .item {
            .thumbnail {
                width: 100%;
            }

            a.embed-delayed {
                position: relative;
                display: block;
                &::after {
                    content: "\25B6";
                    position: absolute;
                    z-index: 1;
                    inset: 50%;
                    font-size: var(--play-size);
                    width: var(--play-size);
                    height: var(--play-size);
                    line-height: 1;
                    transform: translate(-50%, -55%);
                    cursor: pointer;
                    transition: color 0.2s linear;
                    text-shadow: 0px 0px 12px #333333;
                    text-align: center;
                }
                &:hover::after {
                    color: white;
                }
            }

            .info {
                margin-top: 0.4rem;
            }

            .name {
                font-weight: bold;
            }

            .embed {
                margin-block: 0;
            }

            .embed iframe, .embed-delayed .thumbnail {
                border-radius: 5px;
                width: 100%;
                aspect-ratio: 16/9;
            }
        }
    }
}

.embed-video-category {
    a.more {
        display: block;
        text-align: right;
    }
}
