@import "my/grid";

#my_products {
    &.my-tile-grid {
        > div {
            position: relative;
            container-type: inline-size;
            @include make-square;
            &.owned {
                @include flip-card;
            }
            &.not-owned {
                @include greyscale();
                .front {
                    box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.3);
                }
            }
            .flip-card-inner {
                border-radius: $grid-rounding;
            }
            .front, .back {
                @include grid-fill-parent;
                border-radius: $grid-rounding;
                overflow: hidden;
                background-color: var(--color-product-navigation, #DDD);
                @media (max-width: $tablet-bp) {
                    font-size: 0.7rem;
                    font-size: 7cqw;
                }
            }
            .front {
                > img {
                    @include grid-center-element;
                    width: 100%;
                }
                > .label {
                    @include grid-center-element;
                    @include grid-element-label;
                    white-space: nowrap;
                    display: none;
                }
                > .shop-link {
                    @include grid-center-element;
                    --blur: 0.3rem;
                    transform: translate(-50%, 100%);
                    @media (min-width: $tablet-bp) {
                        transform: translate(-50%, 200%);
                    }
                    text-decoration: none;
                    background-color: var(--color-product-dropdown-active-bg, #333a);
                    color: var(--color-product-dropdown-active-fg, #fff);
                    border-radius: 0.3em;
                    padding: 0.5em;
                    white-space: nowrap;
                    border: 0.23rem solid var(--color-product-navigation-text, #BBB);
                    @include pseudo-cover(before);
                    &::before {
                        box-shadow: var(--color-product-navigation-text, #BBB) 0 0 0.6em 0.6em;
                        opacity: 0.3;
                        transition: opacity var(--transspd) ease-in;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.8;
                        }
                        border-color: var(--color-product-navigation-text-hover, #FFF);
                    }
                }
            }
            .back {
                > .label {
                    @include grid-center-element;
                    top: 15%;
                    border-radius: 0.3em;
                    padding: 0.5em;
                    white-space: nowrap;
                    img.logo {
                        width: 100%;
                    }
                }
                > .content {
                    @include grid-center-element;
                    background: #fffd;
                    border-radius: 0.8em;
                    padding: 1.5em;
                    box-shadow: inset #00000082 1px 1px 5px 2px;
                    ul.links {
                        @include naked-list;
                        li {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

    }
}
