$avatar-size: 33px;
$avatar-rounding: 20%;

#user, .menu-toggle {
    .session-avatar {
        display: inline-block;
        height: $avatar-size;
        width: $avatar-size;
        border-radius: $avatar-rounding;
        aspect-ratio: 1/1;
        background-position: center center;
        background-size: cover;
    }

    button:has(.session-avatar) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
