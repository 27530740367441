#issues, #stocks {
    .embed {
        display: block;
        > .video {
            position: relative;
            width: var(--embed-width);
            aspect-ratio: 16/9;
            > iframe {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    figure.image_resized {
        width: var(--embed-width);
        margin-bottom: 0;
        img {
            width: 100%;
        }
    }
}
