/* Generated by Marton on Mon Jun 03 2019 */
@font-face {
    font-family: 'sharedeals icon';
    src: url('fonts/sharedeals-icon.eot');
    src: url('fonts/sharedeals-icon.eot?#iefix') format('embedded-opentype'),
         url('fonts/sharedeals-icon.woff') format('woff'),
         url('fonts/sharedeals-icon.ttf') format('truetype'),
         url('fonts/sharedeals-icon.svg#sharedeals-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-']:before{
	display: inline-block;
   font-family: 'sharedeals icon';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   vertical-align: text-top;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-down:before{content:'\0041';}
.icon-arrow-left:before{content:'\0042';}
.icon-arrow-right:before{content:'\0043';}
.icon-bubble:before{content:'\0044';}
.icon-burger:before{content:'\0045';}
.icon-clap:before{content:'\0046';}
.icon-cross:before{content:'\0047';}
.icon-facebook:before{content:'\0048';}
.icon-lupe:before{content:'\0049';}
.icon-navi-arrow:before{content:'\004a';}
.icon-reply:before{content:'\004b';}
.icon-speech-bubble:before{content:'\004c';}
.icon-to-top:before{content:'\004d';}
.icon-twitter:before{content:'\004e';}
.icon-user:before{content:'\004f';}
