@use '../colors';

.profile {
    h3 {
        font-size: 100%;
        font-weight: 600;
        display: inline-block;
        margin: 0;
        &::after {
            content: ":";
        }
        &::before {
            content: none;
        }
    }

    a {
        i[class^=fa] {
            margin-right: 0.3em;
        }
        @media (min-width: $tablet-bp) {
            white-space: nowrap;
        }
    }

}

#my_profile {
    display: grid;
    gap: 1.5em;
    word-break: break-word;
    grid-template-areas:
        "intro" "pic" "nickname" "nick-links" "nick-note" "ranks" "profile-text";
    @media (min-width: $mobile-bp) {
        grid-template-columns: max-content 1fr 1fr;
        grid-template-areas:
            "intro intro intro"
            "pic nickname nick-links"
            "pic ranks nick-note"
            "pic profile-text profile-text"
        ;
    }
    margin-bottom: 1.5em;

    > .header { grid-area: header; }
    > .intro { grid-area: intro; }
    > .profile-picture { grid-area: pic; }
    > .nickname { grid-area: nickname ; }
    > .nickname-links { grid-area: nick-links; }
    > .nickname-note { grid-area: nick-note; }
    > .ranks { grid-area: ranks; }
    > .profile-text { grid-area: profile-text; }

    .svg-icon {
        width: 1.3em;
    }

    .profile-picture {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .svg-icon, .avatar {
            width: 12rem;
        }
        .avatar {
            border-radius: 5%;
            aspect-ratio: 1/1;
            background-position: center center;
            background-size: cover;
        }
    }

    .nickname {
        font-weight: 600;
        font-size: 200%;
        align-self: center;
        word-break: normal;
        overflow: hidden;
        @media (max-width: $mobile-bp) {
            justify-self: center;
        }
    }

    .nickname-links {
        align-self: center;
        text-align: right;
        a {
            margin-right: 1rem;
        }
        @media (max-width: $mobile-bp) {
            text-align: center;
            a {
                display: block;
                margin-right: 0;
            }
        }
    }
    .nickname-note {
        font-size: 80%;
    }

    .ranks {
        background-color: $text-color-highlight;
        padding: 0.5em;
        border-radius: 0.3em;
        align-self: center;
        .svg-icon {
          display: inline;
        }
    }

    .profile-text {
        a.edit-profile-text {
            float: right;
        }
    }
}

#my_subscriptions {
    display: grid;
    gap: 1.5em;
    word-break: break-word;
    grid-template-areas: "emails" "newsletters";
    @media (min-width: $tablet-bp) {
        grid-template-areas:
            "emails newsletters"
        ;
    }

    > * {
        padding-top: 1.5em;
    }

    > .emails { grid-area: emails; }
    > .newsletters { grid-area: newsletters; }

    fieldset {
        border: 0.2em solid $nbc-blue;
        border-radius: 0.2rem;
        padding: 0.7rem;
        min-height: 100%;
        legend {
            padding: 0 0.3em;
            width: auto;
            font-size: 100%;
        }
    }

    .emails {
        ul {
            list-style: none;
            margin: 1rem 0;
            padding: 0;
            .email {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: 0.3em;
                .icon {
                    &.unconfirmed {
                        filter: hue-rotate(100deg);
                    }
                }
                .address {
                    font-family: monospace;
                    flex-grow: 1;
                }
                .unconfirmed-message {
                    color: $color-warn;
                    font-size: 85%;
                }
                .actions {
                }
            }
        }
        a.add-additional-email {
            margin-left: 0.185em;
        }
    }

    .newsletters {
        h3 {
            display: block;
            margin-bottom: 0.7em;
        }
        button {
            margin-top: 0.7em;
        }
    }
}

form#edit_chat_profile {
    h2 {
        margin-top: 2em;
    }
    .avatar {
        width: 300px;
        border-radius: 5%;
        aspect-ratio: 1/1;
        background-position: center center;
        background-size: cover;
    }
}
