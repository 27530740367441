@import "./_variables";

@keyframes fadeOutFlash {
    0% { opacity: 0.0; }
    5% { opacity: 1.0; }

    6% { filter: saturate(1.0); }
    7% { filter: saturate(5.0); }
    10% { filter: saturate(1.0); }

    13% { filter: saturate(1.0); }
    14% { filter: saturate(5.0); }
    17% { filter: saturate(1.0); }

    90% { opacity: 1.0; }
    100% { opacity: 0.0; }
}

.flash-margin {
    margin-top: 0;
    @media (min-width: $tablet-bp + 1) {
        position: fixed;
        max-width: max(90vw, 200px);
        left: 6vw;
        animation-name: fadeOutFlash;
        animation-duration: 10s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        // under header, but over content
        z-index: 9;
    }
    @media (max-width: $tablet-bp) {
        position: initial;
    }
}

div.validation-errors {
    padding-top: 1.5em;
}

.validation-errors ul {
    padding-inline-start: 1rem;
}

.content.container {
    form.edit_content_page {
        input[type=submit] {
            position: sticky;
            z-index: 1;
            bottom: 1em;
        }
    }
}
