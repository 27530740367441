/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 */

// "bootstrap-sprockets" must be imported before "bootstrap" and "bootstrap/variables"
// @import "bootstrap-sprockets";
// @import "bootstrap";

// Let bootstrap overwrite tailwind for now
@import "../builds/tailwind";

// using bootstrap via yarn instead of rails gem:
// https://medium.com/@guilhermepejon/how-to-install-bootstrap-4-3-in-a-rails-6-app-using-webpack-9eae7a6e2832
@import "bootstrap/variables";
@import "bootstrap/scss/bootstrap";

@import "bootstrap-datepicker/dist/css/bootstrap-datepicker3";
@import "select2/dist/css/select2";
@import "trix/dist/trix"; // von mir hinzugefügt (stammt aus actiontext.scss)
@import "./ckeditor";
@import "actiontext";
@import "simple_calendar";

@import "settings";
@import "utilities";
@import "style";
@import "list-striped";
@import 'form-color-radio';
@import "sharedeals-icon";
@import "sharedeals-elements";
@import "calendar";
@import "priority-indicator";
@import "content-pages";
@import "session";
@import "tipps";
@import "my";
@import "image-zoomable";
@import "issues";
@import "stocks";
@import "videos";
@import "mood-gauge";
@import "speech-bubble";

/* Tweaks for Kaminari Pagination gem */
.pagination {
  justify-content: center;
}

.page-link {
  color: $link-color;
}

.page-item.active .page-link {
    background-color: $link-color;
    border-color: $link-color;
}
/* Tweaks for Kaminari Pagination gem */

span.coverage {
  margin-top: 20px;
}

#header.fixed-top {
    // Do not overlap sticky ck editor when scrolling down
    z-index: 10;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.55);
    @media (max-width: $mobile-bp) {
      max-height: 100vh;
      &:has([aria-expanded="true"]) {
        overflow-y: auto;
      }
    }
}


#memberarea-logo-header {
  width: auto;
  max-width: initial;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    #pusher {
        flex-grow: 1;
    }
    #footer {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
}
